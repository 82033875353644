@use "abstracts/colors";

.light {
  --theme-color: #000;
  --theme-bg-primary-color:#55748E;// #55748e;
  --theme-bg-secondary-color: #e5e5e5;
  --theme-side-menu-primary-color: #1a3d59;//for desktop 2021/2022
  --theme-side-menu-primary-city-color:#AEC7D0;
  --theme-side-menu-secondary-color: #f7f7fc;
  --theme-landing-pg-bg-color: #f7f7fc;
  --theme-text-primary: #1a3d59;
  --theme-text-color: #1a3d59;
  --theme-title-color: #293f47;
  --theme-sub-title-color: #55748e;
  --theme-link-color: #e2f0fa;
  --theme-border-color: #1a3d59;
  --theme-tab-color: #1a3d59;
  --theme-main-tab-title-color: #ffffff;
  --theme-tab-content-color: #3a5864;
  --theme-bg-image: url("../assets/images/light_candidate_lists.jpg");
  --theme-coming-soon-boston-bg-image: url("../assets/images/light_boston-coming soon_candidate list-min.jpg");
  --theme-coming-soon-cleveland-bg-image: url("../assets/images/light_cleveland-coming soon_candidate lists-min.jpeg");
  --theme-seattle-bg-image: url("../assets/images/light_seattle_candidate bg.jpg");
  --theme-active-policy-bg-color: #1a3d59;
  --theme-inactive-policy-bg-color: #cad4d8;
  --theme-candidate-name-color: #f7f7fc;
  --theme-candidate-icons-color: #fff;
  --theme-back-button-color: #fff;
  --theme-contact-text-color: #3a5864;
  --theme-card-text-color: #1a2244;
  --theme-info-bg-color: #d1e7eb;
  --theme-coming-soon-text-color: #1a3d59;
  --theme-coming-soon-info-text-color: #1a3d59;
  --theme-city-election-bg-color: #e2f0fa;
  --theme-city-name-color: #1a3d59;
  --theme-city-stats-bg-color: #36546D;//#8eb0bd SXB
  --theme-city-stats-model-bg-color:#36546D;//for desktop 2021 open
  --theme-city-stats-model-open-bg-color:#D1E7EB;//for  desktop citymodel open
  --theme-position-dropdown-bg-color: #cad4d8;
  --theme-sidebar-city-bg-color:#36546D;// #55748e;xsb
  --theme-side-menu-primary-color-mobile:#AEC7D0;//mobile election color
  --theme-city-stats-model-bg-color-mobile:#D1E7EB;//mobile election open color
  --theme-nav-city-text-color-mobile:#1A3D59;//mobile city text color
  --theme-sidebar-city-submenu-bg-color: #e2f0fa;
  --theme-coming-soon-partition-color: #1a3d59;
  --theme-candidate-profile-border-color: #cad4d8;
  --theme-home-title-border-color: #192f5e;
  --theme-home-text-color: #1a3d59;
  --theme-aboutus-title-about-color: #192f5e;
  --theme-aboutus-title-purplely-color: #5a2d98;
  --theme-aboutus-dot-color: #aec7d0;
  --theme-aboutus-divider-color: #192f5e;
  --theme-aboutus-note-text-color: #192f5e;
  --theme-aboutus-note-font-width: 600;
  --theme-aboutus-note-border-color: rgba(25, 47, 94, 0.8);
  --theme-mobile-candidate-profile-border-color: #e2f0fa;
  --theme-desktop-inactive-policy-bg-color: #cad4d8;
  --theme-video-right-arrow-color: #cad4d8;
  --theme-video-right-arrow-bg-color: #1a3d59;
  --theme-coming-soon-email-bg-color: #f6fefe;
  --theme-coming-soon-notify-bg-color: #cad4d8;
  --theme-election-info-color: #16344c;
  --theme-election-info-textbox-color: #ffffff;
  --theme-gradient-color: rgba(226, 240, 250, 0.4);
  --theme-coming-soon-divider-color: #1a3d59;
  --theme-candidate-profile-border-color: #cad4d8;
  --theme-nav-menu-text-color: #1a3d59;
  --theme-nav-text-hover-color: #8eb0bd;
  --theme-sidebar-icon-color: #1a3d59;
  --theme-candidate-number-color: #1a3d59;
  --theme-city-election-date-color: #1a3d59;
  --theme-candidate-card-color: #1a2244;
  --theme-candidate-card-date-color: #1a3d59;
  --theme-candidate-banner-color: #a462ff;
  --theme-nav-text-focus-color: rgba(174, 199, 208, 0.4);//for mayer active;
  --theme-nav-text-focus-font-color: #1a3d59;
  --theme-about-us-line-color: #192f5e;
  --theme-nav-menu-switch-text-color: #fff;
  --theme-rts-button-bg-color: #1a3d59; /* register to vote (rts) */
  --theme-rts-button-shadow-color: #9da2b1; /* register to vote (rts) */
  --theme-nav-menu-addcity-color: linear-gradient(
    179.87deg,
    #bed0e0 19.63%,
    #aaceec 63.48%,
    #719ec5 87.61%,
    #4d769a 99.89%
  );
  --theme-nav-menu-addcity-text-color: #000000;
  --theme-nav-menu-addcity-purplely-color: #5a2d98;
  --theme-nav-menu-addcity-email-color: #ffffff;
  --theme-nav-menu-addcity-title-color: #fcfcfc;
  --theme-nav-menu-addcity-line-color: #f6fefe;
}

.dark {
  --theme-color: #fff;
  --theme-bg-primary-color: #1f2f35;
  --theme-bg-secondary-color: #2b4148;
  --theme-side-menu-primary-color:#073238;// #0a444d sxb;for desktop 2021/2022
  --theme-side-menu-primary-city-color:#B3D7DF;
  --theme-side-menu-secondary-color: #1f2f35;
  --theme-landing-pg-bg-color: #2b4148;
  --theme-text-primary: #d1e7eb;
  --theme-text-color: #c3f8f8;
  --theme-title-color: #c3f8f8;
  --theme-sub-title-color: #dcf0f1;
  --theme-link-color: #1ae0ff;
  --theme-border-color: #1a3d59;
  --theme-tab-color: #2e5a6b;
  --theme-tab-content-color: #e8fcfc;
  --theme-bg-image: url("../assets/images/seattle-desktop.jpg");
  --theme-coming-soon-boston-bg-image: url("../assets/images/dark_boston-coming soon_candidate list-min.jpg");
  --theme-coming-soon-cleveland-bg-image: url("../assets/images/dark_cleveland-coming soon_candidate list-min.jpg");
  --theme-seattle-bg-image: url("../assets/images/dark_seattle_profile background full.jpg");
  --theme-candidate-profile-border-color: #2b4148;
  --theme-mobile-candidate-profile-border-color: #2b4148;
  --theme-active-policy-bg-color: #007c8f;
  --theme-inactive-policy-bg-color: #f6fefe;
  --theme-desktop-inactive-policy-bg-color: #f6fefe;
  --theme-candidate-name-color: #c3f8f8;
  --theme-candidate-icons-color: #1ae0ff;
  --theme-back-button-color: #c3f8f8;
  --theme-contact-text-color: #e8fdfd;
  --theme-card-text-color: #1f2f35;
  --theme-info-bg-color: #cad4d8;
  --theme-coming-soon-text-color: #fff;
  --theme-coming-soon-info-text-color: #cad4d8;
  --theme-city-election-bg-color: #1f2f35;
  --theme-city-name-color: #f7f7fc;
  --theme-city-stats-bg-color: #0B505A;//#0B505A sxb
  --theme-city-stats-model-bg-color:#0B505A;//sxb for desktop darkmode 2021/2022 open
  --theme-city-stats-model-open-bg-color:#E3F1F3; //for desktop darkmode citymodel open
  --theme-position-dropdown-bg-color: #d1e7eb;
  --theme-sidebar-city-bg-color:#0B505A;// #007c8f;sxb
  --theme-side-menu-primary-color-mobile:#B3D7DF;//mobile election color
  --theme-city-stats-model-bg-color-mobile:#E3F1F3;//mobile election open color
  --theme-nav-city-text-color-mobile:#1F2F35;//mobile city text color
  
  --theme-sidebar-city-submenu-bg-color: #1f2f35;
  --theme-coming-soon-partition-color: #92b4be;
  --theme-home-title-border-color: #f6fefe;
  --theme-home-text-color: #f6fefe;
  --theme-aboutus-title-about-color: #c3f8f8;
  --theme-aboutus-title-purplely-color: #a462ff;
  --theme-aboutus-dot-color: #c3f8f8;
  --theme-aboutus-divider-color: #c3f8f8;
  --theme-aboutus-note-text-color: #1acae6;
  --theme-aboutus-note-font-width: 400;
  --theme-aboutus-note-border-color: rgba(195, 248, 248, 0.8);
  --theme-coming-soon-notify-bg-color: #92b4be;
  --theme-main-tab-title-color: #c3f8f8;
  --theme-desktop-inactive-policy-bg-color: #cad4d8;
  --theme-video-right-arrow-color: #2b4148;
  --theme-video-right-arrow-bg-color: #f6fefe;
  --theme-coming-soon-email-bg-color: #cad4d8;
  --theme-election-info-color: #cad4d8;
  --theme-election-info-textbox-color: #f7f7fc;
  --theme-gradient-color: rgba(31, 47, 53, 0.4);
  --theme-coming-soon-divider-color: #92b4be;
  --theme-nav-menu-text-color: #f6fefe;
  --theme-nav-text-hover-color: #d1e7eb;
  --theme-sidebar-icon-color: #19e0ff;
  --theme-candidate-number-color: #2b4148;
  --theme-city-election-date-color: #e5e5e5;
  --theme-candidate-card-color: #1f2f35;
  --theme-candidate-card-date-color: #1f2f35;
  --theme-candidate-banner-color: #660077;
  --theme-nav-text-focus-color:#293F48;// for mayer active #d1e7eb sxb;
  --theme-nav-text-focus-font-color:#FFFFFF;//#395660;
  --theme-about-us-line-color: #c3f8f8;
  --theme-nav-menu-switch-text-color: #f6fefe;
  --theme-rts-button-bg-color: #007c8f; /* register to vote (rts) */
  --theme-rts-button-shadow-color: #2c3c41; /* register to vote (rts) */
  --theme-nav-menu-addcity-color: radial-gradient(
    62.82% 62.82% at 49.94% 37.18%,
    #02aec9 0%,
    #0290a6 53.49%,
    #007c8f 89.34%
  );
  --theme-nav-menu-addcity-text-color: #f6fefe;
  --theme-nav-menu-addcity-purplely-color: rgb(83, 2, 136);
  --theme-nav-menu-addcity-email-color: #19e0ff;
  --theme-nav-menu-addcity-title-color: #c3f8f8;
  --theme-nav-menu-addcity-line-color: #f6fefe;
}
