@use "abstracts/colors";
@use "abstracts/fonts";

* {
  font-family: fonts.$font-stack;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-smooth: always;
}

body {
  background-color: var(--theme-bg-secondary-color);
  color: colors.$font-primary;
  font-size: fonts.$font-size;
  a {
    color: colors.$dark-primary;
  }
}

button,
a:hover {
  cursor: pointer;
}
a:link {
  text-decoration: none;
}

.main {
  display: flex;
  background-color: var(--theme-landing-pg-bg-color);
  margin-top: 70px;
  margin-left: 235px;
  flex-direction: column;
  //padding-bottom: 60px;
  .mobile__img {
    display: none;
  }
  .dropdown-container {
    display: none;
    margin: 30px 17px;
    .city-dropdown-close {
      button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 15px;
        border: 0;
        position: relative;
        background-color: var(--theme-position-dropdown-bg-color);
        margin: 10px 0 0 0;
        padding: 10px 20px 10px 37px;
        font-size: 20px;
        font-weight: 600;
        color: var(--theme-candidate-number-color);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        z-index: 2;
      }
      .dropdown-menu {
        background-color: var(--theme-position-dropdown-bg-color);
        transform: translate3d(13px, 48px, 0px) !important;
        z-index: 1;
        width: 95%;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        .dropdown-item {
          display: flex;
          align-items: center;
          border-bottom: 1px solid colors.$sidebar-primary;
          padding: 10px 0;
          margin: 0 auto;
          width: 80%;
          color: var(--theme-candidate-number-color);
          font-weight: 600;
          font-size: 16px;
          cursor: pointer;

          img {
            margin-right: 10px;
          }
          &:last-child {
            margin: 8px;
            border: 0;
            @media screen and (max-width: 767px) {
              margin: 0 auto;
            }
          }
        }
      }
      .candidates-number {
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.75px;
        color: var(--theme-candidate-number-color);
      }
      //.collapse{
      .card-body {
        // display: flex;
        // flex-direction: column;
        // align-items:flex-start;
        // justify-content: flex-start;
        margin: 0 20px;
        background-color: var(--theme-position-dropdown-bg-color);
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        p {
          display: flex;
          align-items: center;
          margin: 0 auto;
          color: var(--theme-candidate-number-color);
          font-weight: 600;
          font-size: 16px;
        }
        .accordion {
          background-color: #b8ccce;
          border-radius: 15px;
          margin-top: 1rem !important;
          .btn-link {
            padding: 0 15px;
            background-color: #b8ccce;
            border: 3px solid colors.$dark-primary;
            border-radius: 15px;
            font-size: 16px;
            text-decoration: none;
            margin: 0;
            padding: 2px 0;
          }

          .card-body {
            background-color: #b8ccce;
            padding: 0;
            margin: 0;
            width: 100%;
          }
        }
        .username {
          font-weight: normal;
          font-size: 14px;
          margin-left: 40px !important;

          p {
            font-weight: 600;
            font-size: 16px;
          }
          .party-name {
            font-size: 14px;
            color: var(--theme-candidate-number-color);
            letter-spacing: 0.75px;
            font-weight: 300;
          }
        }
      }
      .main-wrap-accordion {
        border-bottom: 1px solid #1a3d59;
        padding: 10px 0;
        margin: 0 15px;
        display: flex;
        align-items: center;
        width: 90%;
        &:last-child {
          border-bottom: 0;
        }
      }
    }
    //}
  }
  .list-card {
    background: #cad4d8;
    box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    .card-details {
      color: var(--theme-candidate-card-date-color);
      font-size: 17px; /* changed from 14 to 17 -- ELECTION INFO SECTION (ccb) */
      p {
        color: var(--theme-candidate-card-color);
        font-size: 18px;
        line-height: 32px;
        font-weight: 600;
        letter-spacing: 0.75px;
        &.font-18 {
          font-size: 18px;
          color: var(--theme-candidate-card-date-color);
        }
      }
      .party-name {
        font-size: 14px;
        color: var(--theme-candidate-card-color);
        letter-spacing: 0.75px;
        font-weight: 300;
      }
    }
    //MD: Starts
    .banner {
      height: 29px;
      width: 140px;
      background-color: var(--theme-candidate-banner-color);
      box-shadow: 0 4px 2px -2px gray;
      position: absolute;
      top: 12px;
      right: -35px;
      text-align: center;
      color: #fff;
      font-weight: 600;
      font-size: 16px;
      text-transform: uppercase;
      white-space: nowrap;
      -webkit-transform: rotate(35deg);
      -moz-transform: rotate(35deg);
      -ms-transform: rotate(35deg);
      -o-transform: rotate(35deg);
      transform: rotate(35deg);
      padding-top: 4px;
      letter-spacing: 1px;
    }
    //MD: Ends

    @media (max-width: 1440.98px) {
      .card-details {
        p {
          margin: 0 0 0 15px;
        }

        .party-name {
          margin: 0 0 0 15px;
        }
      }
    }
  }

  .e-list-card {
    background: var(--theme-info-bg-color);
  }
  .desktop-wrap {
    .title-text {
      font-size: 35px;
      font-weight: 500;
      letter-spacing: 1px;
      color: var(--theme-text-color);
    }
    .candidate-card {
      cursor: pointer;
    }
  }
}

//Footer Component
.footer__contents {
  background-color: var(--theme-bg-primary-color);
  color: colors.$font-primary;
  font-size: fonts.$font-size;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 235px;
  margin-bottom: -2px;

  .footer__icons {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  svg {
    stroke: var(--theme-link-color);
    border: 2px;
    height: 24px;
    width: 24px;
    padding: 0;
    margin: 5px 10px 0;
  }
}
.disclaimer__content {
  margin-left: 235px;
  background-color: var(--theme-bg-primary-color);
  display: flex;
  padding-top: 12px;
  .disclaimer__text {
    color: #e2f0fa;
    display: flex;
    justify-content: center;
    margin: auto;
    text-align: center;
    font-size: 14px;
    padding-bottom: 5px;
  }
}
// Modal styling desktop starts
.modal-content {
  color: black !important;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  .modal-header {
    border-bottom: none;
    // width: 813px;
    height: 250px;
    background: url(../assets/images/modal-image-dark.jpg) no-repeat center
      center;
    background-size: 100% 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    @media screen and (max-width: 1000px) {
      background: url(../assets/images/modal-mobile-dark.jpg) no-repeat center
        center/100%;
    }
    @media screen and (max-width: 415px) {
      background: url(../assets/images/modal-mobile-dark.jpg) no-repeat center
        center/100%;
      height: 20rem;
      margin-bottom: -20px;
    }
    @media screen and (max-width: 350px) {
      background: url(../assets/images/modal-mobile-dark.jpg) no-repeat center
        center/100%;
      height: 20rem;
      margin-bottom: -40px;
    }
    @media screen and (max-width: 350px) and (max-height: 568px) {
      background: url(../assets/images/modal-mobile-dark.jpg) no-repeat center
        center/100%;
      height: 18rem;
      margin-bottom: -40px;
    }
  }

  .modal-body {
    // width: 813px;
    height: 292px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
.container-modal {
  display: flex;
  flex-direction: column;
}
.modal-text-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 5rem;
  line-height: 50px;
  /* or 104% */
  letter-spacing: 1px;
  color: var(--theme-nav-menu-addcity-title-color);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.content-modalBody {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10%;
  background: var(--theme-nav-menu-addcity-color);
}

.content-modalText {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 3rem;
  line-height: 38px;
  /* or 158% */
  letter-spacing: 0.75px;
  color: var(--theme-nav-menu-addcity-text-color);
  a {
    color: colors.$link-primary;
  }
  .coloredWord {
    color: var(--theme-nav-menu-addcity-purplely-color);
  }
  .coloredLink {
    color: var(--theme-nav-menu-addcity-email-color);
  }
}

.line-addCity {
  text-align: center;
  padding-left: auto;
  padding-right: auto;
  .line {
    width: 202px;
    height: 47px;
    border-bottom: 3px solid var(--theme-nav-menu-addcity-line-color);
    transform: rotate(90deg);
    position: relative;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 1000px) {
    display: none;
  }
}
// Modal styling desktop ends

// NavBar Component
.navbar {
  background-color: var(--theme-bg-primary-color);
  background-image: linear-gradient(
    rgba(85, 116, 142, 0.2),
    rgba(255, 255, 255, 0.2)
  );
  padding: 18px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  border-radius: 0;

  button {
    border: none;
    outline: none;
  }
  button.navbar-toggler:focus {
    outline: 1px none !important;
    box-shadow: none !important;
  }
  .navbar-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 22px;
    .menu-link {
      ul {
        display: flex;
        margin: 0;
        padding-left: 0;
        li {
          list-style: none;
          padding: 0 20px;
          border-right: 1px solid #e2f0fa;
          &:last-child {
            border: none;
            padding-right: 0;
          }
          a,
          div {
            font-size: 18px;
            color: #e2f0fa;
            line-height: 34px;
            letter-spacing: 1px;
            cursor: pointer;
          }
        }
      }
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .brand__name {
      font-weight: fonts.$font-weight;
      font-size: 24px;
      color: colors.$font-primary;
      display: flex;
      justify-content: center;
      div {
        text-decoration: none;
        color: #f7f7fc;
        font-size: 24px;
        line-height: 34px;
        letter-spacing: 1px;
        cursor: pointer;
      }
    }
    .mode-change-btn {
      color: #f7f7fc;
      padding: 10px;
      position: absolute;
      right: 25px;
      top: 12px;
      border-radius: 50%;
      font-size: 30px;
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 767px) {
        right: 15px;
        top: 13px;
        height: 40px;
        width: 40px;
      }
    }
  }
  .menu-bars {
    font-size: 1.6rem;
    line-height: 1;
    display: none;
    svg {
      color: white;
    }
  }
}

.landing__bio {
  background-color: var(--theme-landing-pg-bg-color);
  display: flex;
  align-items: center;
  padding: 60px;
  .side-line {
    border-right: 5px solid var(--theme-home-title-border-color);
  }
  h1 {
    color: var(--theme-text-primary);
    font-weight: bold;
    font-size: 93px;
    line-height: 110px;
    background-color: var(--theme-landing-pg-bg-color);
    //margin-right: 24px;
  }

  p {
    color: var(--theme-home-text-color);
    font-size: fonts.$landingp__font-size;
    font-weight: fonts.$landingp__font-weight;
    line-height: fonts.$landingp__line-height;
    letter-spacing: 2px;
    padding: 20px 0 0 52px;
    background-color: var(--theme-landing-pg-bg-color);
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding: 36px;
    h1 {
      font-size: 64px;
      line-height: 70px;
      border: none;
      padding-right: 0;
    }
    p {
      font-size: 18px;
      line-height: 25px;
      padding-left: 0;
    }
  }
  @media screen and (max-width: 1199.99px) {
    .side-line {
      border: 0;
    }
    p {
      padding-left: 0;
    }
  }
}

.nav-menu {
  margin-top: 72px;
  height: calc(100vh - 70px);
  width: 235px;
  z-index: 20;
  background-color: var(--theme-bg-primary-color);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: fixed;
  top: -1px;
  left: 0px;
  transition: 450ms;
  .loading_skele {
    display: flex;
    justify-content: center;
    margin: 8px auto 8px auto;
  }

  .nav-text {
    justify-content: start;
    align-items: center;
    list-style: none;
    //background-color: colors.$dark-primary;
    //border-bottom: 2px solid colors.$dark-secondary;
    &:first-child {
      padding: 0px;
    }
    a {
      color: var(--theme-nav-menu-text-color);
      // background-color:#AEC7D0;
      font-weight: normal;
      padding: 13px;
      justify-content: center;
      @media screen and (max-width: 767px) {
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
      }
      &:hover {
        color: colors.$dark-primary;
        background-color: var(--theme-nav-text-hover-color);
        height: 100%;
        width: 100%;
      }
    }
    &.light-bg {
      background-color: var(--theme-sidebar-city-submenu-bg-color);
    }
  }
  a {
    text-decoration: none;
    color: colors.$font-primary;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
  }
  a:hover {
    background-color: colors.$sidebar-secondary;
    color: colors.$sidebar-font-primary;
  }
  .navbar-toggle {
    background-color: var(--theme-sidebar-city-bg-color);
    p {
      color: #f6fefe;
      font-size: 48px;
      font-weight: 500;
    }
  }

  .addCity {
    background-color: var(--theme-side-menu-primary-color);
    padding: 13px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    // line-height: 24px;
    border-radius: 0px !important;
    margin-bottom: 0.5px;
    outline: none;
    text-decoration: none;
    cursor: pointer;
  }
  .addCity:focus {
    background: var(--theme-city-stats-bg-color);
    color: var(--theme-coming-soon-text-color);
    font-weight: 600;
  }

  .nav-menu-items {
    width: 100%;
    font-weight: 600;
    height: calc(100vh - 170px);
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #cad4d8;
    }
    &::-webkit-scrollbar {
      width: 6px;
      background-color: #cad4d8;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #334851;
    }
    .footer__about {
      display: none;
    }

    .mobile-sidebar-footer { // https://www.w3schools.com/css/css_positioning.asp (2022 ccb)
      // position: fixed;
      position:sticky;
      bottom: 0px;
      text-align: center;
      width: auto;//228px
      font-size: 2rem;
      margin-left: 0px;
      padding-top: 1.4rem;//0.75
      height: 9vh;
      background-color: var(--theme-sidebar-city-bg-color);
    }
    .menu-dropdown-wrap {
      position: unset;
      margin-top: 1px;
      &.show {
        .menu-dropdown {
          background: #8eb0bd;
          border-radius: 4px;
          color: #1a3d59;
          &:after {
            transform: rotate(0deg);
          }
        }
      }
      .menu-dropdown {
        background-color: var(--theme-side-menu-primary-color);//sxb
        padding: 13px;
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        // line-height: 24px;
        border-radius: 0px !important;
        margin-bottom: 0.5px;
        outline: none;
        text-decoration: none;
       
        .city-name {
          white-space: break-spaces;
          width: 180px;
          color:#FFFFFF;
          font-weight: 400;
        }
        &:after {
          display: inline-block;
          margin-left: 0.255em;
          vertical-align: 0.255em;
          content: "";
          border-top: 0.3em solid;
          border-right: 0.3em solid transparent;
          border-bottom: 0;
          border-left: 0.3em solid transparent;
          transform: rotate(268deg);
        }
        &.btn-open {
          background: var(--theme-city-stats-model-bg-color);
          color: var(--theme-coming-soon-text-color);
          font-weight: 400;//chenge from 400 to 600 sxb
          &:after {
            color: #FFFFFF;
            transform: rotate(0deg);
          }
        }
        &:focus {
          border: 0;
          box-shadow: none;
          outline: 0;
        }
      }

      //@at-root crear a class for MOBILE 
      .menu-dropdown-mobile {
        background-color:var(--theme-side-menu-primary-color-mobile) ;//sxb
        padding: 13px;
        font-size: 20px;
        font-weight: 400;
        color: #fff;
        // line-height: 24px;
        border-radius: 0px !important;
        margin-bottom: 0.5px;
        outline: none;
        text-decoration: none;
        .city-name {
          white-space: break-spaces;
          width: 100vw; // was 181px (2022 ccb)
          color:var(--theme-nav-city-text-color-mobile);
          text-align: center;
          font-weight: 600;
        }
        // &:after {
        //   display: inline-block;
        //   margin-left: 0.255em;
        //   vertical-align: 0.255em;
        //   content: "";
        //   border-top: 0.3em solid;
        //   border-right: 0.3em solid transparent;
        //   border-bottom: 0;
        //   border-left: 0.3em solid transparent;
        //   transform: rotate(268deg);
        // }
        &.btn-open {
          background: var(--theme-city-stats-model-bg-color-mobile);
          color: var(--theme-coming-soon-text-color);
          font-weight: 400;//chenge from 400 to 600 sxb
          // &:after {
          //   color: #FFFFFF;
          //   transform: rotate(0deg);
          // }
        }
        &:focus {
          border: 0;
          box-shadow: none;
          outline: 0;
        }
      }

      //creat a new dropdown for special city sxb
      .menu-dropdown2 {
        background-color: var(--theme-side-menu-primary-city-color);
        // background-color: #AEC7D0;
        padding: 13px;
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        // line-height: 24px;
        border-radius: 0px !important;
        margin-bottom: 0.5px;
        outline: none;
        text-decoration: none;
        .city-name {
          white-space: break-spaces;
          width: 180px;
          color:#1A3D59;
        }
        &:after {
          display: inline-block;
          margin-left: 0.255em;
          vertical-align: 0.255em;
          content: "";
          border-top: 0.3em solid;
          border-right: 0.3em solid transparent;
          border-bottom: 0;
          border-left: 0.3em solid transparent;
          transform: rotate(268deg);
          color:#192F5E;
          
      
        }
        &.btn-open {
          background: var(--theme-city-stats-model-open-bg-color);
          color: var(--theme-coming-soon-text-color);
          font-weight: 400;//chenge from 600 to 400
          &:after {
            transform: rotate(0deg);
          }
        }
        &:focus {
          border: 0;
          box-shadow: none;
          outline: 0;
        }
      }

      .dropdown-menu {
        padding: 0px;
        position: unset !important;
        width: 100%;
        border-radius: 0;
        border: 0;
        transform: unset !important;
        background-color: var(--theme-side-menu-secondary-color);
        color: var(--theme-text-primary);
        a {
          font-weight: 600;
          padding: 0;
          color: var(--theme-text-primary);
        }
        .dropdown-item {
          padding: 12px 20px 12px 20px;
          color: var(--theme-text-primary);
          border-bottom: 1px solid var(--theme-city-stats-bg-color);
          font-weight: normal;
          font-size: 16px;
          white-space: unset;
          background-color: var(--theme-side-menu-secondary-color);
          cursor: pointer;
          &:hover {
            color: var(--theme-nav-text-focus-font-color);
            background-color: var(--theme-nav-text-focus-color);
          }
          &:focus {
            background-color: #cad4d8;
          }
        }
        .active {
          background-color: var(--theme-nav-text-focus-color);
          color: var(--theme-nav-text-focus-font-color);
        }
      }
    }
    .mobile-view-item {
      display: none;
    }
    .sidebar-icon {
      display: none;
    }

    @media screen and (min-width: 767px) {
      .division-line {
        display: none;
      }
    }
    @media screen and (max-width: 767px) {
      .division-line {
        padding: auto;
        margin: auto;
        content: "";
        width: 145px;
        height: 1px;
        background: var(--theme-nav-menu-text-color);
      }
      .footer__about {
        display: flex;
        justify-content: center;
        flex-direction: row;
        //position: absolute;
        // bottom: 30px;
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        color: var(--theme-nav-menu-text-color);
        //  &:before {
        //    content: '';
        //    position: absolute;
        //    width: 145px;
        //    height: 1px;
        //    background: var(--theme-nav-menu-text-color);
        //    top: -10px;
        //  }
      }
      .footer__addcity {
        display: flex;
        justify-content: center;
        flex-direction: row;
        //position: absolute;
        // bottom: 102px;
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        color: var(--theme-nav-menu-text-color);
        background-color: transparent;
        // &:before {
        //   content: '';
        //   position: absolute;
        //   width: 145px;
        //   height: 1px;
        //   background: var(--theme-nav-menu-text-color);
        //   top: 10px;
        // }
      }
    }
  }
  h1 {
    background-color: colors.$sidebar-primary;
    color: colors.$font-primary;
    font-size: fonts.$landing__font-size;
    padding-left: 20px;
  }
  .sidebar__icons svg {
    margin: 20px;
    color: colors.$link-primary;
    position: relative;
    bottom: -170px;
    left: 55px;
  }
  //.footer__icons {
  //  padding: 10px 60px;
  //  ._icons {
  //    display: flex;
  //    a {
  //      padding: 0;
  //      svg {
  //        margin: 0;
  //      }
  //    }
  //  }
  //}
}
.mainsite {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  flex: 1;
  .main {
    flex: 1;
    overflow: auto;
    //margin-bottom: 50px;
    //@media screen and (max-width: 767px) {
    //  margin-bottom: 65px;
    //}
  }
}
.mainimage {
  width: 100%;
  //max-width: 1205px !important;
}
.city-dropdown {
  background-color: colors.$dropdown-primary;
  color: colors.$dark-primary;
}
.slider-wrap {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  .bg-img {
    width: 100%;
  }
  .data-display-wrap {
    display: none;
    margin: 46px 0;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .city-stats {
      text-align: center;
      background-color: var(--theme-city-election-bg-color);
      padding: 9px;
      font-size: 24px;
      font-weight: bold;
      //border: 1px solid colors.$font-secondary;
      width: 80%;
      border-radius: 30px 30px 0 0;
      color: var(--theme-city-name-color);
    }
    .city-stats-data {
      background-color: var(--theme-city-election-bg-color);
      padding: 5px 18px;
      text-align: left;
      font-size: 14px;
      //border: 1px solid colors.$font-secondary;
      border-top: 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80%;
      .label {
        margin-bottom: 0;
        flex: 0.5;
        font-size: 16px;
        color: var(--theme-city-name-color);
        letter-spacing: 1px;
        text-align: left; // alignment fix (ccb)
      }
      .data {
        flex: 1;
        background: var(--theme-info-bg-color); // color fix mobile (ccb)
        text-align: center;
        border-radius: 30px;
        font-size: 16px;
        line-height: 28px;
        color: var(--theme-candidate-card-date-color); // color fix mobile (ccb)
        @media screen and (max-width: 320px) {
          font-size: 12px;
        }
      }
      &:last-child {
        border-radius: 0 0 30px 30px;
        padding-bottom: 28px;
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media screen and (max-width: 767.98px) {
  .main {
    margin-left: 0;
    .choice__img {
      max-width: 100%;
      height: auto;
    }
    .desktop__img {
      display: none;
    }
    .mobile__img {
      display: block;
    }
    .dropdown-container {
      display: block;
      .city-dropdown-close {
        &.show {
          .city-dropdown {
            .down {
              display: none;
            }
            .up {
              display: block;
            }
          }
        }
        .city-dropdown {
          .up {
            display: none;
          }
          &:after {
            display: none;
          }
        }
        .dropdown-menu {
          width: 92%;
        }
        button {
          text-decoration: none;
          outline: none;
        }
        .main-wrap-accordion {
          display: flex;
          flex-direction: row;
          // justify-content: space-around;
          align-items: center;
          //MD: Starts
          .banner {
            height: 20px;
            width: 70px;
            background-color: var(--theme-candidate-banner-color);
            box-shadow: 0 4px 2px -2px gray;
            text-align: center;
            color: #fff;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 0.75px;
            text-transform: uppercase;
            white-space: nowrap;
            border-radius: 5px;
            margin-top: -6px;
            padding-top: 2px;
            margin-right: -10px;
          }
          .avatar-winner {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          //MD: Ends
        }
      }
    }
    .desktop-wrap {
      display: none;
    }
    //.mainimage{
    //  display: none;
    //}
  }
  .slider-wrap {
    //background-image: var(--theme-bg-image);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 245px;
    .bg-img {
      display: none;
    }
    .data-display-wrap {
      display: flex;
    }
  }
  .navbar {
    padding: 15px 20px;
    .menu-bars {
      display: block;
    }
    .navbar-title {
      width: unset;
      margin-left: auto;
      margin-right: auto;
      .brand__name {
        a {
          font-weight: normal;
          font-size: 24px;
          line-height: 0px;
        }
      }
    }
  }
  .nav-menu {
    left: -240px;
    margin-top: 67px;
    height: calc(100vh - 54px);
    &.active {
      background-color: var(--theme-city-election-bg-color);
      left: 0;
      transition: 450ms;
    }
    .nav-menu-items {
      //by ccb
      // overflow: hidden;
      // height: calc(100vh - 80px);
      overflow: auto; // (2022 ccb) // was hidden
      height: calc(100vh - 110px);
      .nav-text {
        display: none;
      }
      .sidebar-icon {
        display: unset;
        position: absolute;
        bottom: -5px;
        // display: none;
        div {
          display: flex;
          justify-content: center;
          font-size: 20px;
          padding: 0;
          margin: 0;
          a {
            display: unset;
            width: unset;
            padding: 0;
            margin: 0;
          }
        }
        svg {
          margin: 0 15px;
          color: var(--theme-sidebar-icon-color);
        }
      }
      .mobile-view-item {
        display: block;
        border: 0;
        &:hover {
          background: var(--theme-sidebar-city-bg-color);
        }
        .nav-header {
          &:hover {
            background: none;
          }
        }
      }
      .sidebar-overflow {
        width: 100%;
        // overflow-y: auto;
        //change by ccb
        // height: calc(100vh - 300px);
        overflow-y: auto;
        // height: calc(100vh - 300px); // disabled (2022 ccb)
        .menu-bars {
          p {
            text-align: center;
          }
        }
      }
    }
    .navbar-toggle {
      padding: 15px 0;
      margin: 0;
      width: 100%;
      border-radius: 0;
      p {
        line-height: 1;
      }
    }
  }
  .footer__contents {
    margin-left: 0;
  }
  .disclaimer__content {
    background-color: var(--theme-bg-primary-color);
    margin: 0 10px;
  }
  .party-name {
    color: var(--theme-candidate-number-color);
  }
}
@media (min-width: 1441px) {
  .main {
    .list-card {
      margin: 0 30px 0 0;
      .card-details {
        p {
          margin: 0 0 0 30px;
        }
        .party-name {
          margin: 0 0 0 30px;
        }
      }
    }
  }
}

.city-form {
  margin: 100px 260px;
}

.position-form,
.candidate-form,
.election-form {
  margin-top: 20px;
  margin-left: 260px;
  margin-right: 260px;
}
.switch-text {
  position: fixed;
  z-index: 111;
  right: 70px;
  font-size: 16px;
  top: 30px;
}
.center-switch {
  margin-right: 50px;
}
.switch {
  width: 50px;
  height: 24px;
  position: relative;
  //right: 0;
  z-index: 111;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}

.avatar-circle {
  width: 55px;
  height: 55px;
  background-color: colors.$dark-primary;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}
.initials {
  position: relative;
  top: 15px; /* 25% of parent */
  font-size: 25px; /* 50% of parent */
  line-height: 25px; /* 50% of parent */
  color: #fff;
  font-family: "Courier New", monospace;
  font-weight: bold;
}
.m-avatar-circle {
  width: 37px;
  height: 37px;
  background-color: colors.$dark-primary;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  margin-left: 16px;
}
.m-initials {
  position: relative;
  top: 10px; /* 25% of parent */
  font-size: 20px; /* 50% of parent */
  line-height: 20px; /* 50% of parent */
  color: #fff;
  font-family: "Courier New", monospace;
  font-weight: bold;
}
.footer-section {
  //position: fixed;
  //bottom: 0;
  width: 100%;
  background-color: var(--theme-bg-primary-color);
  height: 96px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
}
.dark-text {
  color: var(--theme-nav-menu-switch-text-color);
  font-size: 14px;
  padding-left: 0 !important;
}
