@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

$font-stack: 'Poppins', sans-serif;
$font-size: 1.25rem;
$font-weight: 400;

$landing__font-size: 4.2rem;
$landing__font-weight: 700;
$landing__line-height: 70px;

$landingp__font-size: 24px;
$landingp__line-height: 38px;
$landingp__font-weight: 400;
