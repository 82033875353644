@use "../abstracts/colors";
@use "../abstracts/fonts";

* {
  font-family: fonts.$font-stack;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.main-tab-wrap {
  .p-bottom {
    padding-bottom: 100px;
  }
}

.candidateLanding--navBar {
  display: grid;
  grid-template-columns: 100px auto;
  background-color: var(--theme-tab-color);
}

.main-page-wrap {
  .profile-wrap {
    //background-image: var(--theme-seattle-bg-image);
    background-size: cover;
    flex-direction: column;
    background-position: bottom;
    .profile-img {
      border: 2px solid var(--theme-candidate-profile-border-color);
    }
    .image-social-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 110px;
    }
  }

  .avatar-circle {
    width: 243px;
    height: 243px;
    background-color: colors.$dark-primary;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .initials {
      position: unset;
      font-size: 120px; /* 50% of parent */
      //line-height: 25px; /* 50% of parent */
      color: #fff;
      font-family: "Courier New", monospace;
      font-weight: normal;
    }
  }

  .user-name {
    display: flex;
    flex-direction: row;
    p {
      font-size: 48px;
      font-weight: 700;
      line-height: 50px;
      letter-spacing: 1px;
      color: var(--theme-candidate-name-color);
    }
  }

  .social-icon {
    a {
      color: var(--theme-candidate-icons-color);
      svg {
        font-size: 30px;
        margin: 0 15px;
      }
    }
  }
}

.tab-wrap {
  background-color: var(--theme-tab-color);
  font-size: 18px;
  line-height: 72px;
  padding: 12px 0;
  position: relative;
  &.nav-tabs {
    border: 0;

    .nav-link {
      background-color: transparent;
      border: 0;
      color: var(--theme-main-tab-title-color);
      padding: 0 1rem;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.8px;
      font-style: normal;
      text-align: center;
    }
  }
}

.forward-icon {
  border: 2px solid var(--theme-back-button-color);
  color: var(--theme-back-button-color);
  box-sizing: border-box;
  width: 35px;
  padding: 1px 4px;
  margin: 0 2rem 0 7.5rem;
  border-radius: 40px;
  line-height: 1;
  font-size: 2rem;
  cursor: pointer;
  svg {
    vertical-align: bottom;
  }
  justify-self: center;
  align-self: center;
}

.tab-title {
  color: var(--theme-title-color);
  border-bottom: 1px solid var(--theme-title-color);
  margin: 90px 30px 30px 30px;
  h1 {
    font-size: 48px;
    line-height: 66px;
    font-weight: 500;
    letter-spacing: 1px;
  }
  p {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.25px;
    color: var(--theme-sub-title-color);
    a {
      // display: flex;
      // align-items: center;
      font-size: 18px;
      line-height: 20px;
      font-weight: 400;
      letter-spacing: 0.25px;
      color: var(--theme-sub-title-color);
    }
  }
}

.bio-title {
  margin: 30px 30px;
}

.bio-description {
  font-size: 18px;
  color: var(--theme-tab-content-color);
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.75px;
  margin: 0 30px;

  .slider-btn-next {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 50%;
    height: 28px;
    width: 28px;
    font-size: 2rem;
    color: var(--theme-candidate-profile-border-color);
    background-color: var(--theme-video-right-arrow-bg-color);
    position: absolute;
    top: 40%;
    right: -40px;
    box-shadow: none;
    &:active,
    &:focus {
      background-color: var(--theme-video-right-arrow-bg-color) !important;
      border: 0 !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }
  .slider-btn-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 50%;
    height: 28px;
    width: 28px;
    font-size: 2rem;
    color: var(--theme-candidate-profile-border-color);
    background-color: var(--theme-video-right-arrow-bg-color);
    position: absolute;
    top: 40%;
    left: -40px;
    box-shadow: none;
    &:active,
    &:focus {
      background-color: var(--theme-video-right-arrow-bg-color) !important;
      border: 0 !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }
  .slick-list {
    .slick-slide {
      margin-right: 10px;
    }
  }

  .slick-arrow {
    &:before {
      content: unset;
    }
  }

  .contact-email {
    color: var(--theme-contact-text-color);
    text-decoration: underline;
  }
}
.slick-slider {
  margin: 0 40px 0 40px;
}

.contact-info {
  padding-bottom: 150px;
}

.policy-null {
  padding-bottom: 100px;
}

.policy-wrap {
  .accordion > .card {
    border: 0;
    border-radius: 16px;
  }
  .policy-card {
    background-color: transparent;
    margin: 12px 30px;
    .inactive-header {
      background-color: var(--theme-desktop-inactive-policy-bg-color);
      border-radius: 16px;
      padding: 0;
      button {
        color: #293f47;
        background-color: var(--theme-desktop-inactive-policy-bg-color);
        border: 0;
        padding: 10px 15px;
        font-weight: 500;
        border-radius: 16px;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.75px;
      }
    }
    .active-header {
      background-color: var(--theme-active-policy-bg-color);
      border-radius: 16px;
      padding: 0;
      button {
        color: #ffffff;
        background-color: var(--theme-active-policy-bg-color);
        border: 0;
        padding: 10px 15px;
        font-weight: 500;
        border-radius: 16px;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.75px;
      }
    }
    .policy-data {
      margin: 20px 25px 0 25px;
      color: var(--theme-tab-content-color);
      font-size: 18px;
      line-height: 30px;
      font-weight: 400;
      letter-spacing: 0.75px;
      .sub-bullet-title {
        padding: 15px 0 15px 0;
      }
      .sub-bullet {
        margin-left: 50px;
      }
    }
    .card-body {
      padding: 0 1.25rem;
    }
  }
  .expansion-icon {
    svg {
      font-size: 26px;
    }
  }
  .p-bottom {
    padding-bottom: 100px;
  }
}

@media (min-width: 320px) and (max-width: 767.98px) {
  .main-page-wrap {
    .image-wrap {
      width: 35%;
      height: 145px;
      margin: 0 24px 10px 0;
    }
    .social-icon {
      a {
        svg {
          font-size: 18px;
          margin: 0 10px;
        }
      }
    }
    .profile-wrap {
      flex-direction: row;
      padding: 0 20px;
      .profile-img {
        height: 97px;
        width: 97px;
        border: 2px solid var(--theme-mobile-candidate-profile-border-color);
      }
    }
    .user-name {
      display: flex;
      flex-direction: column;
      p {
        font-size: 32px;
        margin-left: 10px;
        margin-bottom: 20px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 36px;
      }
    }
    .avatar-circle {
      width: 97px;
      height: 97px;
      .initials {
        font-size: 50px;
      }
    }
  }
  .policy-wrap {
    .policy-card {
      margin: 12px 0;
      .active-header {
        button {
          color: #f7f7fc;
        }
      }
      .inactive-header {
        background-color: var(--theme-inactive-policy-bg-color);
        button {
          background-color: var(--theme-inactive-policy-bg-color);
        }
      }
      .active-header,
      .inactive-header {
        button {
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.75px;
          font-weight: 600;
          text-align: left;
        }
        @media (max-width: 402px) {
          button {
            text-align: left;
            font-weight: 500;
          }
          .tab-icon-wrap {
            margin-right: 1px;
          }
        }
      }
      .policy-data {
        margin: 0 20px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.25px;
        color: var(--theme-tab-content-color);
        .sub-bullet {
          margin-left: 25px;
        }
      }
      .card-body {
        padding: 1.25rem;
      }
    }
  }
  .tab-title {
    margin: 80px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 5px;
    h1 {
      font-size: 20px;
      line-height: 32px;
      font-weight: 600;
      letter-spacing: 0.75px;
    }
    p {
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: 0.25px;
      color: var(--theme-sub-title-color);
      a {
        // display: flex;
        // align-items: center;
        font-size: 16px;
        line-height: 22px;
        font-weight: 300;
        letter-spacing: 0.75px;
        color: var(--theme-sub-title-color);
      }
    }
    svg {
      font-size: 22px;
      line-height: 16px;
      font-weight: 300;
      letter-spacing: 0.25px;
      margin-left: 5px;
    }
  }
  .bio-title {
    margin: 0;
  }
  .bio-description {
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    letter-spacing: 0.75px;
    margin: 10px 0;
    .slider-btn-next {
      // right: -35px;
    }
  }
  .forward-icon {
    width: 25px;
    padding: 1px;
    margin: 0 1rem 0 2.5rem;
    font-size: 1.8rem;
    color: var(--theme-candidate-name-color);
  }
  .main-tab-wrap {
    .tab-wrap {
      line-height: 40px;
      padding: 10px 0px;
      &.nav-tabs {
        .nav-link {
          font-size: 14px;
          line-height: 26px;
          font-weight: 400;
          letter-spacing: 0.6px;
          padding: 0 9px;
          margin: 0;
          color: var(--theme-candidate-name-color);
        }
      }
    }
    .contact-div {
      padding-bottom: 120px;
    }
  }
  .contact-info {
    padding-bottom: 120px;
  }
  .candidateLanding--navBar {
    grid-template-columns: 75px auto;
  }
}
