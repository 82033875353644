// Dark theme colors
$dark-primary: #1f2f35;
$dark-secondary: #2b4148;
$light-primary: #55748e;
$light-secondary: #e5e5e5;

// font colors
$font-primary: #f6fefe;
$font-secondary: #c3f8f8;

//text link primary color
$link-primary: #19e0ff;

// sidebar color
$sidebar-primary: #007c8f;
$sidebar-secondary: #d1e7eb;
$sidebar-font-primary: #0a444d;

// dropdown color
$dropdown-primary: #fff;
