@use '../abstracts/colors';
@use '../abstracts/fonts';

.about_us {
  overflow-x: hidden;
  background-color: var(--theme-landing-pg-bg-color);
  color: #1a3d59;
  .overview {
    padding: 56px 56px;
    display: flex;
    align-items: center;
    color: var(--theme-home-text-color);
    .title {
      line-height: 100px;
      .text_about {
        color: var(--theme-aboutus-title-about-color);
      }
      .text_purplely {
        color: var(--theme-aboutus-title-purplely-color) !important;
      }
    }
    .dot {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: var(--theme-aboutus-dot-color);
      margin-left: -10px;
    }
    .left {
      display: flex;
      font-size: 90px;
      font-weight: bold;
      align-items: center;
      padding: 0;
      .divider {
        width: 5px;
        min-height: 300px;
        background-color: var(--theme-aboutus-divider-color);
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }
    }
    .right {
      font-size: 16px;
      padding-left: 5vw;

      p {
        margin-bottom: 24px;
      }
      .mobile {
        display: none;
      }
    }
    img {
      margin-top: 56px;
    }
    .info {
      font-size: 18px;
      font-weight: 300;
      padding: 60px 16vw;
      .text_purplely {
        color: var(--theme-aboutus-title-purplely-color) !important;
      }
      p {
        margin-bottom: 36px;
      }
      .purplely {
        font-weight: 600;
        font-size: 32px;
      }
    }
    .notes {
      font-family: fonts.$font-stack;
      box-sizing: border-box;
      padding: 30px 0;
      margin: 0 6vw;
      font-size: 24px;
      font-style: italic;
      font-weight: var(--theme-aboutus-note-font-width);
      line-height: 30px;
      color: var(--theme-aboutus-note-text-color);
      border-top: 2px solid var(--theme-aboutus-note-border-color);
    }
  }
}

@media (min-width: 320px) and (max-width: 767.98px) {
  .about_us {
    .overview {
      padding: 56px 32px;
      .title {
        line-height: 70px;
      }
      .left {
        flex-direction: column;
        align-items: flex-start;
        font-size: 64px;
        padding: 0;
        .divider {
          display: none;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 0;
        .mobile {
          display: block;
        }
        .dot {
          margin-left: 0;
        }
        .bar {
          width: 5px;
          height: 30px;
          background-color: var(--theme-about-us-line-color);
        }
      }
      .info {
        padding: 32px 0;
        p {
          margin-bottom: 24px;
        }
        .purplely {
          font-size: 28px;
        }
      }
      img {
        margin-bottom: 30px;
      }
      .notes {
        font-size: 16px;
        padding: 16px 0;
        margin: 0;
      }
    }
  }
}
