/* Scroll Back To The Top Feature*/
/* STT (CCB) */

@use '../abstracts/colors';
@use '../abstracts/fonts';

.scroll-to-top {
  position: fixed;
  bottom: 0px;
  right: 0px;
  height: 30px;
  width: 30px;
  z-index: 10;
  color: #B3D7DF;
  font-size: 25px;
  text-align: center;
}

.scroll-to-top {
background-color: #1A3D59;
}
