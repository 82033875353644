@use '../abstracts/colors';
@use '../abstracts/fonts';

.background-wrap {
  background-size: cover;
  background-position: right;
  overflow-y: auto;
  height: calc(100vh - 155px);
  .divider {
    border-right: 5px solid var(--theme-coming-soon-divider-color);
  }
  .election-section {
    font-size: 48px;
    line-height: 50px;
    font-weight: 700;
    letter-spacing: 1px;
    color: var(--theme-election-info-color);
  }
  .list-card {
    padding: 8px; // was 18px (ccb)
    background-color: var(--theme-election-info-textbox-color);
    .card-details {
      font-size: 18px;
      line-height: 28px;
      font-weight: normal;
      letter-spacing: 0.75px;
      color: #153147;
      p {
        font-size: 24px;
        font-weight: bold;
        color: #1a3d59;
        line-height: 50px;
        letter-spacing: 1px;
      }
    }
  }
  .title-text {
    font-size: 96px;
    font-weight: 700;
    color: var(--theme-text-color);
    line-height: 100px;
    letter-spacing: 5.55px;
  }
  .get-notified {
    margin-top: 40px;
    font-size: 18px;
    line-height: 38px;
    letter-spacing: 0.75px;
    font-weight: 500;
    color: var(--theme-coming-soon-text-color);
  }
  .form-label {
    color: var(--theme-coming-soon-text-color);
    font-size: 16px;
    line-height: 19px;
    font-weight: normal;
    font-style: normal;
  }
  .input-wrap {
    input:active {
      border: 0;
    }
  }
  .email-wrap {
    background-color: var(--theme-coming-soon-email-bg-color);
    border-radius: 15px;
    padding: 0px 15px;
    font-size: 16px;
    box-shadow: none;
    line-height: 34px;
    letter-spacing: 0.75px;
    font-weight: normal;
    border: 0;
    color: black;
    &:focus-visible {
      outline: none;
    }
  }
  .notify-btn {
    @media (min-width: 767.98px) {
      top: 0;
      right: 0;
      position: absolute;
    }
    border-radius: 15px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    border: 0;
    padding: 5px 25px;
    color: #1a3d59;
    background-color: var(--theme-coming-soon-notify-bg-color);
    &:active {
      background-color: var(--theme-coming-soon-notify-bg-color) !important;
      color: #1a3d59 !important;
    }
  }
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: none !important;
  background-color: var(--theme-coming-soon-notify-bg-color);
}
.input-wrap {
  letter-spacing: 0.75px;
  width: 80%;
  margin-right: auto;
  user-select: none;
  border: 0;
  @media screen and (max-width: 767px) {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    label {
      font-size: 14px;
      line-height: 16px;
      margin-top: 10px;
    }
  }
}

.boston {
  background-image: linear-gradient(
      90deg,
      var(--theme-gradient-color),
      var(--theme-gradient-color)
    ),
    var(--theme-coming-soon-boston-bg-image);
}

.cleveland {
  background-image: linear-gradient(
      90deg,
      var(--theme-gradient-color),
      var(--theme-gradient-color)
    ),
    var(--theme-coming-soon-cleveland-bg-image);
}

@media (min-width: 320px) and (max-width: 767.98px) {
  .main {
    margin-top: 65px;
  }
  .background-wrap {
    height: calc(100vh - 150px);
    padding: 0;
    ::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-y: scroll;
    overflow-x: hidden;
    .title-text {
      font-size: 64px;
      line-height: 70px;
      letter-spacing: 1px;
      text-align: center;
      font-weight: bold;
      margin: 0 50%;
    }
    .get-notified {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.75px;
      font-weight: 500;
      text-align: center;
      margin: 5px 0 14px 0;
    }
    .coming-soon-section {
      .light,
      .dark {
        border-right: 0;
      }
      .custom-mob-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .email-wrap {
        margin-bottom: 5px;
        width: 100%;
        line-height: 15px;
        &::placeholder {
          font-size: 16px;
          line-height: 38px;
          letter-spacing: 0.75px;
          font-weight: normal;
          color: rgba(26, 61, 89, 0.5);
          text-align: center;
        }
      }
    }
    .election-section {
      font-size: 24px;
      line-height: 50px;
      margin-bottom: 10px;
      letter-spacing: 1px;
      font-weight: bold;
      text-align: center;
      label {
        color: #1a2244;
        font-weight: normal;
        font-size: 14px;
        line-height: 100%;
      }
    }
    .width-fix {
      width: 110px;
      font-size: 16px;
      line-height: 50px;
      color: var(--theme-coming-soon-info-text-color);
      font-weight: 700;
      text-transform: uppercase;
      margin-right: 8px;
    }
    .list-card {
      padding: 0 18px;
      background: #f7f7fc;
      border-radius: 15px;
      .card-details {
        font-size: 15px;
        line-height: 28px;
        letter-spacing: 0.25px;
        p {
          font-size: 16px;
          color: rgba(26, 61, 89, 0.8);
          font-weight: bold;
          line-height: 50px;
          letter-spacing: 1px;
        }
      }
      @media (max-width: 350px) {
        padding: 0 5px;
        .card-details {
          font-size: 12px;
        }
      }
    }
    .notify-btn {
      width: 100%;
      padding: 5px;
      font-weight: 600;
      font-size: 18px;
      line-height: 15px;
      &:active {
        background-color: var(--theme-coming-soon-notify-bg-color);
      }
    }
    .divider {
      border-right: 0;
    }
    .input-wrap {
      border: 0;
    }
    .form-label {
      text-align: center;
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .background-wrap {
    .get-notified {
      text-align: center;
    }
    .coming-soon-section {
      .email-wrap {
        width: 100%;
      }
      .custom-mob-section {
        margin-bottom: 80px;
      }
    }
    .title-text {
      text-align: center;
    }
    .divider {
      border-right: 0;
    }
  }
}

@media (min-width: 992px) and (max-width: 1299.98px) {
  .background-wrap {
    .coming-soon-section {
      .email-wrap {
        width: 100%;
      }
    }
    .election-section {
      font-size: 40px;
      color: var(--theme-coming-soon-info-text-color);
    }
    .title-text {
      font-size: 70px;
      line-height: 70px;
    }
    .input-wrap {
      width: 100%;
      margin: 0;
      border: 0;
    }
    .form-label {
      flex-direction: column !important;
    }
  }
}

@media (min-width: 1300px) {
  .background-wrap {
    .input-wrap {
      input:active {
        border: 0;
      }
    }
    .email-wrap {
      width: 100%;
    }
  }
}

/* register-to-vote (rtv) */
.register-to-vote {
  .rtv {
    background-color: var(--theme-rts-button-bg-color);
    border-radius: 200px;
  }  
  .rtv-desktop {
    box-shadow: 1px 4px 3px 2px var(--theme-rts-button-shadow-color);
  }
  .rtv-mobile {
    box-shadow: 0px 3px 5px 2px var(--theme-rts-button-shadow-color);
    width: 95%;
  }
  .rtv-mobile-cs { /* cs = coming soon */
    width: 100%;
  }
  .rtv-p {
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 0.05em;
  }
  .rtv-p-desktop {
    font-size: 20px;
  }
  .rtv-p-mobile {
    font-size: 16px;    
  }
}
